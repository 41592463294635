<template>
  <div>
    <general-table
      ref="categoryTable"
      api-url="category-tickets"
      add-type="Add Category"
      type="modal"
      :columns="columns"
      :selectable="false"
      add-component-name="add-category"
      edit-component="edit-category"
      :delete-content="false"
      :view-content="false"
      :search-box="false"
      guard="friends"
    />
    <div>
      <b-modal
        id="category"
        ref="genmodal"
        hide-footer
        centered
        size="md"
        no-close-on-backdrop
        :title="$store.state.generalIds.id ? 'Update Category' : 'Add Category'"
      >
        <add-edit-category />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditCategory from './control-category/AddEditCategory.vue'

export default {
  components: { GeneralTable, AddEditCategory },
  data() {
    return {
      columns: [
        { key: 'id', sortable: false },
        { key: 'name', label: 'Name' },
        { key: 'name_ar', label: 'Arabic Name' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
