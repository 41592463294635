import { ref } from '@vue/composition-api'

export default function category() {
  const categoryForm = ref({
    name: '',
    name_ar: '',
  })

  const viewData = ref({})

  return {
    categoryForm,
    viewData,
  }
}
